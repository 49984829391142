<script setup lang="ts">
import Session from 'supertokens-web-js/recipe/session';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';

definePageMeta({
  layout: false,
});

const config = useRuntimeConfig();

// Get vars for development only
const email = ref(config.public.USERNAME);
const password = ref(config.public.PASSWORD);

const error = ref(false);
const emailError = ref('');
const passwordError = ref('');

const router = useRouter();
const { query } = useRoute();

// Remove query params from the URL
router.replace({ query: {} });

if (query.logout) {
  try {
    Session.signOut().then(() => console.log('logged out'));
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

const loading = ref(false);
async function signIn() {
  error.value = false;
  loading.value = true;

  try {
    const response = await EmailPassword.signIn({
      formFields: [
        {
          id: 'email',
          value: email.value,
        },
        {
          id: 'password',
          value: password.value,
        },
      ],
    });

    if (
      response.status === 'WRONG_CREDENTIALS_ERROR' ||
      response.status === 'SIGN_IN_NOT_ALLOWED'
    ) {
      // the input email / password combination did not match,
      // so we show an appropriate error message to the user
      loading.value = false;
      error.value = true;
      return;
    }

    if (response.status === 'FIELD_ERROR') {
      response.formFields.forEach(item => {
        if (item.id === 'email') {
          // this means that something was wrong with the entered email.
          // probably that it's not a valid email (from a syntax point of view)
          loading.value = false;
          emailError.value = item.error;
        } else if (item.id === 'password') {
          passwordError.value = item.error;
        }
      });
      return;
    }

    // login is successful, and we redirect the user to the home page.
    // Note that session cookies are added automatically and nothing needs to be
    // done here about them.
    window.location.href = config.public.APP_URL;
  } catch (error) {
    console.error(error);
    loading.value = false;
  }
}
</script>

<template>
  <NuxtLayout name="default">
    <div
      v-if="query.resetPassword || query.accountRecovery || error"
      class="mb-4">
      <SolaraAlert v-if="query.resetPassword" variant="info">
        {{ $t('success.PASSWORD_RESET_SUCCESS') }}</SolaraAlert
      >
      <SolaraAlert v-else-if="query.accountRecovery" variant="info">
        {{ $t('success.ACCOUNT_RECOVERY_SUCCESS') }}</SolaraAlert
      >
      <SolaraAlert v-else-if="error" variant="warn">
        {{ $t('errors.WRONG_CREDENTIALS_ERROR') }}</SolaraAlert
      >
    </div>

    <form class="space-y-6" @submit.prevent="signIn">
      <SolaraInput
        id="email"
        v-model="email"
        :label="$t('login.email')"
        test-id="email"
        name="email"
        type="email"
        autocomplete="email"
        required="true"
        :icon="!loading ? 'user' : 'lock'" />

      <div>
        <div class="flex items-center justify-between">
          <label
            for="password"
            class="block text-sm font-medium leading-6 text-gray-900"
            >{{ $t('login.password') }}</label
          >
          <div class="text-sm">
            <SolaraButton
              :disabled="loading"
              :loading="loading"
              to="/account-recovery"
              type="link"
              test-id="forgot-password-btn"
              variant="link"
              >{{ $t('login.forgot_password') }}</SolaraButton
            >
          </div>
        </div>
        <div class="mt-2">
          <SolaraInput
            id="password"
            v-model="password"
            test-id="password"
            name="password"
            type="password"
            autocomplete="current-password"
            required="true"
            icon="lock" />
        </div>
      </div>

      <div>
        <SolaraButton
          :disabled="!email || !password"
          :loading
          type="submit"
          width="full"
          test-id="login-btn"
          class="justify-center rounded-xl drop-shadow-md">
          <template v-if="loading">
            <SolaraIcon icon="loading_spinner" class="animate-spin" />
          </template>
          {{ loading ? '' : $t('login.submit') }}
        </SolaraButton>
      </div>
    </form>
  </NuxtLayout>
</template>
